<template>
    <div class="info-page">
        <div class="info-page__cover">
            <div class="cover-content-privacy">
                <h1 class="cover-content-privacy__title">{{ info.page.h1 }}</h1>
            </div>
        </div>

        <LayoutInfoPages :content="info.page.content || ''" />
    </div>
</template>

<script lang="ts" setup>
import type { Api } from '~~/global'

const { buildHeaders, baseUrl, endpoints } = useApiClient()

const { data: responseData, error } = await useAsyncData('help-page-data', () => {
    return $fetch<Api.Responses.Info.Basic>(endpoints.info.help.path, {
        headers: buildHeaders(),
        method: 'GET',
        baseURL: baseUrl,
    }).catch((e) => e.data || false)
})

if (error.value || !responseData.value || responseData.value.error) {
    throw createError({
        statusCode: responseData.value?.feedback === 'resource_not_found' ? 404 : 500,
        message:
            responseData.value?.feedback === 'resource_not_found' ? 'Página no encontrada' : 'Algo salió mal',
    })
}

const info = ref(responseData.value)

const Route = useRoute()
const {
    public: {
        variant: {
            metaData: { siteOrigin },
        },
    },
} = useRuntimeConfig()

useSeoMeta({
    title: info.value.page.title,
    ogUrl: siteOrigin + Route.path,
    ogTitle: info.value.page.title,
    description: info.value.page.description,
    ogDescription: info.value.page.description,
    ogImageAlt: info.value.page.title,
    twitterCard: 'summary_large_image',
})

useHead({
    link: [
        {
            rel: 'canonical',
            href: siteOrigin + Route.path,
        },
    ],
})
</script>

<style lang="postcss" scoped>
.info-page {
    &__cover {
        @apply relative mb-2 h-auto overflow-hidden;
        .cover-content-privacy {
            @apply container p-6;
            &__title {
                @apply w-full text-center text-[28px] font-medium lg:text-[32px];
            }
            &__img {
                @apply hidden w-32 md:block;
            }
        }
    }
}
</style>
